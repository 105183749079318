//@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&display=swap');


$red: #0A95FF;
$dark: #222;

* {
  font-family: 'Maven Pro', sans-serif;
}

.fiyat-text{
  font-family: 'Open Sans', sans-serif;
}

.text-primary {
  color: $red !important;
}
body {
  background-color: $red;
}

.cursor-sepet {
  cursor: url(/public/sepet.svg), auto;
}

.bg-primary {
  background-color: $red !important;
}
.h-text-primary:hover {
  color: $red !important;
}
.btn-disabled:disabled{
  opacity: 0.5;
}
.parlat{
  box-shadow: 1px 2px 129px -10px rgba(255,255,255,1)
}
.button {
  background-color: rgba($red,1) !important;
  border-bottom: 2px solid $red;
  border-radius: 0.25rem;
  font-weight: 600;
  color: white;
  transition: 0.2s;

  &:disabled{
    opacity: 0.5;
  }

  &:hover {
    opacity: 0.7;
  }

  &:active {
    transform: scale(0.9);
  }
}

.main {
  background-color: $red;
}

.page {
  max-width: 590px;
  width: 100%;
  margin: 0 auto;
  padding: 2rem 0;
  padding-bottom: 7rem;
}

.nav {
  max-width: 590px;
  width: 100%;
  bottom: 20px;
  z-index: 10000;
  left: 50%;
  transform: translate(-50%, 0);
}

.search {
  background-color: $dark;
  .search-border {
    border: 2px dashed white;
  }
}

#search-input {
  color: white;
  outline: none !important;

  &::placeholder {
    color: white;
  }
}
.h-24-important{
  height: 6rem !important;
}
.skeleton {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #dddbdd;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.7) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 2s infinite;
    content: "";
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

.h-full-important {
  height: 100% !important;
}

.login-box {
  max-width: 320px;
}
.show-urun-edit {
  transition: 0.2s;
  transform: scale(0);
  opacity: 0;
}

.kantin-urun:hover {
  .show-urun-edit {
    transform: scale(1);
    opacity: 1;
  }
}

.br-8{
  border-radius: 8px;
}

.b-hover{
  transition: .2s;
  &:hover{
    opacity: 0.7;
  }
}

.login-big-box{
  width: 100%;
  max-width: 1200px;
  

  margin: 3rem auto;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 5rem 10rem;
  background-image: url(https://cdn.sstatic.net/Img/home/illo-code.svg?v=b7ee00fff9d8);
  background-repeat: no-repeat;
  background-position: 100px 80%;
  background-color:#232629 ;
  .pbox{
    padding: 20px;
    border-radius: 8px;
    width: 45%;
    position: relative;

    button{
      border-radius: 4px;
      padding: 4px 1rem;
      
    }
  }

  .pbox-turuncu{
    background-color: #FEE3CD;
    border-bottom-right-radius: 0;

    .pri{
      color:#F2740D;
    }

    &:after{
      clip-path: polygon(18px 0, 32px 0, 0 40px, 0 38px, 0 0, 18px 0);
      background-color: #FEE3CD;
      width: 32px;
      height: 32px;
      position: absolute;
      top:99%;
      left: auto;
      right:0;
      transform: scaleX(-1);
      display: block;
      content: '';
      border-radius: 0 5px 0;
    }

    button{
      background-color: #F2740D;
    }
  }

  .pbox-mavi{
    background-color: #CDE9FE;
    border-bottom-left-radius: 0;
    .pri{
      color:#0A95FF;
    }
    &:after{
      clip-path: polygon(18px 0, 32px 0, 0 40px, 0 38px, 0 0, 18px 0);
      background-color: #CDE9FE;
      width: 32px;
      height: 32px;
      position: absolute;
      top:99%;
      left: 0;

      display: block;
      content: '';
      border-radius: 0 5px 0;
    }

    button{
      background-color: #0A95FF;
    }
  }
}


.max-scroll{
  max-height: 360px;
  overflow-y: auto;
}